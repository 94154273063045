<template>
  <div
    id="trs-page-container"
    class="d-flex justify-center"
  >
    <common-trs-app-bar
      clipped-left
      color="white"
      flat
      :profile="profiledataItems"
      :is-viewer="true"
      @navigateToNotifications="showAlert"
      @navigateToCommunication="goToCommunication"
    />
  </div>
</template>

<script>
  // Utilities
  import { mapState, mapMutations } from 'vuex'
  export default {
    name: 'AppHeader',

    components: {
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      showAppsList: false,
      profile: [{ title: 'Access Control', to: 'SettingsAccess' }],
    }),

    computed: {
      currentRole () {
        return this.$store.getters['auth/roles']
      },
      source () {
        return this.$store.getters['auth/source']
      },
      isHolder () {
        return this.currentRole.includes(this.CONSTS.USER_ROLES.HOLDER)
      },
      isTappAdmin () {
        return this.currentRole.includes(this.CONSTS.USER_ROLES.TAPP_ADMIN)
      },
      isCaptableAdmin () {
        return this.currentRole.includes(this.CONSTS.USER_ROLES.CAPTABLE_ADMIN)
      },
      isSuperAdmin () {
        return this.$store.getters['auth/isSuperAdmin']
      },
      profiledataItems () {
        const toShowProfileDetails = !this.isHolder && !this.isTappAdmin && (this.source === this.CONSTS.CAPTABLE_SOURCES_TYPES.V2)
        return toShowProfileDetails ? (this.isCaptableAdmin || this.isSuperAdmin ? this.profile : []) : []
      },
      isTenantEnabled () {
        return this.$store.getters['account/tenantStatus'].is_active
      },
      profileData () {
        return this.$store.getters['account/profile']
      },
      user () {
        return this.$store.getters['auth/user']
      },
      ...mapState(['drawer']),
      portal () {
        return this.$getPortal()
      },
      userProfile () {
        return {
          avatar: (this.profileData.profile_details || {}).logo_url,
          avatarText: `${this.user.first_name} ${this.user.last_name}`,
          group: '/profile',
          title: `${this.user.first_name} ${this.user.last_name}`,
          subtitle: `TID: ${this.profileData.trellis_uid}`,
          children: [
            {
              to: 'me',
              title: 'My Profile',
            },
          ],
        }
      },

    },

    methods: {
      ...mapMutations('app', {
        setDrawer: 'SET_DRAWER',
      }),
      showAlert () {
        if (this.$vuetify.breakpoint.mdAndDown) {
          this.setDrawer(!this.drawer)
        }
        this.$store.commit('app/setShowAlertModal', true)
      },
      showAppsListItem (val) {
        this.showAppsList = !this.showAppsList
      },

      goToCommunication () {
        this.$router.push({ name: 'SystemNotifications.List' })
      },
    },
  }
</script>
<style lang="scss" scoped>
</style>
